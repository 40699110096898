<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "เพิ่ม" : "แก้ไข" }} ข้อมูล</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6 pb-0">
        <vs-input label="ชื่อเข้าใช้งาน" v-model="usernameId" class="mt-5 w-full" name="item-usernameId"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('item-usernameId')">{{ errors.first('item-usernameId') }}</span>
      </div>

      <div class="p-6 pt-0 pb-0">
        <vs-input label="ชื่อ-สกุล" v-model="userFullname" class="mt-5 w-full" name="item-userFullname"
                  v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('item-userFullname')">{{ errors.first('item-userFullname') }}</span>
      </div>

      <div class="p-6 pt-0 pb-0">
        <div class="mt-5 w-full">
          <p class="text-sm mt-5 w-full">ตำแหน่ง/แผนก</p>
          <vs-input v-model="positionname"/>
        </div>
      </div>

      <div class="p-6 pt-0 pb-0">
        <vs-input label="รหัสผ่าน" v-model="passwordId" class="mt-5 w-full" name="item-password"
                  v-validate="'required'" type="password" />
        <span class="text-danger text-sm" v-show="errors.has('item-password')">{{ errors.first('item-password') }}</span>
      </div>


    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">ตกลง</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">ยกเลิก</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'

  export default {
    name: 'DataViewSidebar',
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    components: {
      VuePerfectScrollbar,
      vSelect,
    },
    data() {
      return {
        options: [{label: 'True', value: 'True'}, {label: 'False', value: 'False'}],
        basic_options: [{label: 'true', value: 'true'}, {label: 'false', value: 'false'}],
        selectedBasicKey: {label: '', value: ''},
        selectedAdmin: {label: '', value: ''},
        selectedReportKey: {label: '', value: ''},
        selectedP31Key: {label: '', value: ''},
        selectedP32Key: {label: '', value: ''},
        dataId: null,
        usernameId: "",
        userFullname: "",
        passwordId: "",
        positionname: "",
        P32Key: "",
        P31Key: "",
        ReportKey: "",
        AdminKey: "",
        BasicKey: "",
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return;
        if (Object.entries(this.data).length === 0) {
          this.initValues();
          this.$validator.reset()
        } else {
          let {usernameId, userFullname, passwordId, positionname, id} = JSON.parse(JSON.stringify(this.data));
          this.dataId = id;
          this.usernameId = usernameId;
          this.userFullname = userFullname;
          this.passwordId = passwordId;
          this.positionname = positionname;
          this.initValues()
        }
        // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
            // this.$validator.reset()
            // this.initValues()
          }
        }
      },
      department() {
        let options = [];
        this.$store.state.department.items.forEach(function (item) {
          options.push({label: item.DepartOrPosition, value: item.DepartOrPosition})
        });
        return options
      },
      isFormValid() {
        return !this.errors.any()
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      initValues() {
        if (this.data.id) return;
        this.dataId = null;
        this.usernameId = "";
        this.passwordId = "";
        this.userFullname = "";
        this.positionname = "";
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const obj = {
              id: this.dataId,
              usernameId: this.usernameId,
              userFullname: this.userFullname,
              passwordId: this.passwordId,
              positionname: this.positionname,
            };
            if (obj.id !== null) {
              this.$store.dispatch("user/updateItem", obj)
                .then(() => {
                  this.$store.dispatch("user/fetchDataListItems")
                })
            } else {
              delete obj.id;
              this.$store.dispatch("user/addItem", obj).then(() => {
                this.$store.dispatch("user/fetchDataListItems")
              })
            }

            this.$emit('closeSidebar');
            this.initValues()
          }
        })
      }
    }, created() {
    },
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
  }
</style>
